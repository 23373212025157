import * as React from "react"
import { Helmet } from "react-helmet"
import { Container } from 'semantic-ui-react'


// markup
const IndexPage = () => {
  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Swati Snacks Menus</title>
      </Helmet>
      <h1><a href="./ahmedabad">Ahmedabad Menu</a></h1>
      <h1><a href="./mumbai">Mumbai Menu</a></h1>
    </Container>
  )
}

export default IndexPage
